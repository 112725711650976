<template>
  <v-data-table
    :headers="headers"
    :items="packages"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="700"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มแพ็กเกจ
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.package_name"
                          label="ชื่อแพ็กเกจ"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.package_name.$silentErrors"
                          v-if="v$.editedItem.package_name.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-select
                          v-model="editedItem.product_type_id"
                          label="ประเภทแพ็กเกจ *"
                          :items="product_types"
                          item-text="product_type_name"
                          item-value="product_type_id"
                        >
                        </v-select>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.product_type_id.$silentErrors"
                          v-if="v$.editedItem.product_type_id.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12">
                        <v-text-field
                          type="number"
                          v-model="editedItem.package_price"
                          label="ค่าแพ็กเกจ(บาท)"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.package_price.$silentErrors"
                          v-if="v$.editedItem.package_price.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12">
                        <v-text-field
                          type="number"
                          v-model="editedItem.package_item_amt"
                          label="จำนวน(ชิ้น)"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.package_item_amt.$silentErrors"
                          v-if="v$.editedItem.package_item_amt.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12">
                        <v-text-field
                          type="number"
                          v-model="editedItem.price_pe_item"
                          label="ราคาชิ้น(บาท)"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.price_pe_item.$silentErrors"
                          v-if="v$.editedItem.price_pe_item.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12">
                        <v-text-field
                          type="number"
                          v-model="editedItem.package_package_price"
                          label="ค่าซอง(บาท)"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.package_package_price.$silentErrors"
                          v-if="v$.editedItem.package_package_price.$error">{{
                            index > 0 ? ', ' : ''
                          }}
                          {{ err.$message }}</small>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12">
                        <v-textarea
                          v-model="editedItem.package_detail"
                          label="รายละเอียดแพ็กเกจ"
                        ></v-textarea>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.package_detail.$silentErrors"
                          v-if="v$.editedItem.package_detail.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.code_promotion"
                          label="รหัสโปรโมชั่น"
                        ></v-text-field>
                      </v-col>


                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >

                        <DatePicker
                          v-model="editedItem.start_promotion"
                          value-type="format"
                          format="YYYY-MM-DD"
                          placeholder="วันที่เริ่มต้น"
                          label="วันที่เริ่มต้น"
                        ></DatePicker>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <DatePicker
                          v-model="editedItem.end_promotion"
                          value-type="format"
                          format="YYYY-MM-DD"
                          placeholder="วันที่สิ้นสุด"
                          label="วันที่สิ้นสุด"
                        ></DatePicker>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="number"
                          v-model="editedItem.installment_month"
                          label="แบ่งผ่อนชำระ/เดือน"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="number"
                          v-model="editedItem.percent_promotion"
                          label="ส่วนลด(%)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-checkbox
        value="Y"
        @click="updateStatusPackage(item.package_id)"
        v-model="item.package_status">
      </v-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_package from '../../../services/package'
import instance_product_type from '../../../services/productType'
import Swal from "sweetalert2";
import {email, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  components: {DatePicker},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    packages: [],
    product_types: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      {text: 'ชื่อแพ็กเกจ', value: 'package_name'},
      {text: 'ประเภทแพ็กเกจ', value: 'product_type.product_type_name', sortable: false},
      {text: 'ราคาแพ็กเกจ', value: 'package_price'},
      {text: 'จำนวน', value: 'package_item_amt'},
      {text: 'ราคาต่อชิน', value: 'price_pe_item'},
      {text: 'สถานะ (เปิด/ปิด)', value: 'status', sortable: false},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      package_name: '',
      product_type_id: '',
      price_pe_item: '',
      package_item_amt: '',
      package_price: '',
      package_package_price: '',
      package_detail: '',
      code_promotion: '',
      start_promotion: '',
      end_promotion: '',
      installment_month: '',
      percent_promotion: '',
    },
    defaultItem: {
      package_name: '',
      product_type_id: '',
      price_pe_item: '',
      package_item_amt: '',
      package_price: '',
      package_package_price: '',
      package_detail: '',
      code_promotion: '',
      start_promotion: '',
      end_promotion: '',
      installment_month: '',
      percent_promotion: '',
    },
  }),
  validations() {
    return {
      editedItem: {
        package_name: {required},
        package_item_amt: {required},
        package_detail: {required},
        package_package_price: {required},
        package_price: {required},
        price_pe_item: {required},
        product_type_id: {required},
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม แพ็กเกจ' : 'แก้ไข แพ็กเกจ'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getPackage()
      },
    },
    search: {
      handler() {
        this.getPackage()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getPackage()
    this.getProductType()
  },

  methods: {
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.packages.indexOf(item)
      this.editedItem = {...item}
      var product_type_id = this.editedItem.product_type_id

      if (product_type_id) {
        Object.entries(this.product_types).forEach(([key, val]) => {
          if (val.product_type_id == product_type_id) {
            product_type_id = val;
            return false;
          }

        });
        this.editedItem.product_type_id = product_type_id;
      }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.packages.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },
    updateStatusPackage(id) {
      this.$store.state.isLoading = true
      instance_package.packageUpdateStatus(id).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.$store.state.isLoading = false

      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      });
    },
    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_package.packageDestroy(this.editedItem.package_id).then(res => {

        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }, 150)
        this.getPackage()
        this.closeDelete()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    },

    close() {
      this.v$.$reset();
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return

      if (this.editedIndex > -1) {
        this.$store.state.isLoading = true

        this.editedItem.product_type_id = this.editedItem.product_type_id && this.editedItem.product_type_id.product_type_id ? this.editedItem.product_type_id.product_type_id : ''
        instance_package.packageUpdate(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150)
            this.close()
            this.getPackage()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {
        instance_package.packageStore(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150)
            this.getPackage()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false
        });
      }
    },
    getPackage() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        status: '',
        date: ''
      }
      instance_package.getPackage(data).then(res => {
        this.packages = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      });
    },
    getProductType() {
      this.$store.state.isLoading = true
      var data = {
        search: '',
        date: '',
        status: '',
        size: 999999,
        page: 1,
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }

      instance_product_type.get(data).then(res => {
        this.product_types = res.data.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
